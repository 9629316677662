<template>
    
    <div class="h-24 rounded-lg bg-filters px-1 py-2 mb-2 flex flex-row">

        <div class="h-auto w-1 flex-none rounded-full" :style="'background-color:' + legendColors[row.id_sl].color + ';'">
        </div>

        <div class="flex-1 flex flex-col">

            <div class="h-6 pb-3 px-2">
                <span class="text-xs text-white font-semibold"> {{ row.name }}</span>
            </div>

            <div class="flex-1 min-h-0 flex flex-col">

                <div class="h-1/2 w-full flex flex-row justify-start items-center">
                    
                    <div class="h-full w-1/4  flex flex-col justify-center items-center">
                        <span class="text-3xs text-white font-semibold mb-1">ANSR</span>
                        <span class="text-3xs font-semibold "
                            :class="{'text-success': row.ner >= 0, 'text-danger':row.ner<0}">
                            {{ row.ner || 0 | reduceBigNumbers(2) }}€
                        </span>
                    </div>

                    <div class="h-full w-1/4   flex flex-col justify-center items-center">
                        <span class="text-3xs text-font-gray  mb-1">vs Obj</span>
                        <span class="text-3xs font-semibold "
                            :class="{'text-success': row.ner_vs_objective > 0, 'text-danger':row.ner_vs_objective <= 0}">
                            <span v-if="row.ner_vs_objective>0">+</span>{{ row.ner_vs_objective || 0 | reduceBigNumbers(2) }}<span v-if=" row.ner_vs_objective!='TBD'">%</span>
                        </span>
                    </div>

                    <div class="h-full w-1/4 flex flex-col justify-center items-center">
                        <span v-if="row.TNR != 'N/A'" class="text-font-gray text-3xs font-semibold mb-1">TNR</span>
                        <span v-if="row.TNR != 'N/A'" class="text-3xs font-semibold"
                            :class="{'text-success': row.TNR > 0, 'text-danger': row.TNR <= 0}">
                            {{ row.TNR || 0 | reduceBigNumbers(2) }}€
                        </span>
                    </div>

                   <div class="h-full w-1/4 flex flex-col justify-center items-center">
                        <span class="text-font-gray text-3xs font-semibold mb-1">Rate</span>
                        <span class="text-3xs font-semibold"
                            :class="{'text-success': row.average_rate >= 0, 'text-danger':row.average_rate < 0}">
                            {{ row.average_rate || 0 | reduceBigNumbers(2) }}€/h
                        </span>
                    </div>

                    

                </div>

                <div class="h-1/2 w-full flex flex-row justify-start items-center">

                    <div class="h-full w-1/4   flex flex-col justify-center items-center">
                        <span class="text-3xs text-font-gray  mb-1">Ubbilled</span>
                        <span class="text-3xs font-semibold "
                            :class="{'text-success': row.unbilled >= 0, 'text-danger':row.unbilled<0}">
                            {{ row.unbilled || 0 | reduceBigNumbers(2) }}€
                        </span>
                    </div>

                    <div class="h-full w-1/4   flex flex-col justify-center items-center">
                        <span class="text-3xs text-font-gray  mb-1">PMC</span>
                        <span class="text-3xs font-semibold"
                            :class="{'text-success': row.pm_payment >= 0, 'text-danger':row.pm_payment<0}">
                            {{ row.pm_payment | reduceBigNumbers(0) }}d
                        </span>
                    </div>

                    <div class="h-full w-1/4 flex flex-col justify-center items-center">
                        <span class="text-white text-3xs font-semibold mb-1">Margin</span>
                        <span class="text-3xs font-semibold"
                            :class="{'text-success': row.margin >= 0, 'text-danger':row.margin<0}">
                            {{ row.margin || 0 | numberFormat(2) }}%
                        </span>
                    </div>
                    
                    <div class="h-full w-1/4 flex flex-col justify-center items-center">
                        <span class="text-font-gray text-3xs font-semibold mb-1">vs Obj</span>
                        <span class="text-3xs font-semibold"
                            :class="{'text-success': row.margin_vs_objective >= 0, 'text-danger': row.margin_vs_objective < 0}">
                            <span v-if="row.margin_vs_objective>0">+</span>{{ row.margin_vs_objective || 0 | reduceBigNumbers(2) }}<span v-if=" row.margin_vs_objective!='TBD'"> ptos.</span>
                        </span>
                    </div>
                    
                    
                </div>

            </div>

        </div>
    
    </div>

</template>

<script>
import { state } from '@/store';

    export default {
        name: 'ProjectsSMUMobile',
        props: ['row'],
        computed: {
            legendColors() { 
                return state.slLegendColors 
            }
        }
    }
</script>